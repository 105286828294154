import { ChangeEvent, useState } from "react";
import { Modal } from "../Modal";
import { createId } from "../request/createId";

type Props = {
  onClose: (isSuccess: boolean) => void;
};

export const CreateIdModal = (props: Props) => {
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [createdId, setCreatedId] = useState<number | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [refNumber, setRefNumber] = useState<string>("");
  const [lot, setLot] = useState<string>("");
  const create = () => {
    setIsCreating(true);
    setError("");
    createId(refNumber, lot)
      .then(setCreatedId)
      .catch((e) => {
        if (e === 403) {
          setError("Unauthorized");
        } else if (e === 409) {
          setError("Conflict");
        } else {
          setError("Unknown error");
        }
      })
      .then(() => {
        setIsCreating(false);
      });
  };
  var regex = /^[\dA-z-]{3,10}$/;
  const isValid = regex.test(lot) && regex.test(refNumber);

  const handleLotChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLot(e.target.value);
    setError("");
  };
  const handleRefNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRefNumber(e.target.value);
    setError("");
  };

  let contents = null;
  let buttons = [];

  if (createdId === null) {
    contents = (
      <>
        <div className="Field-group">
          <div className="Field-label">REF:</div>
          <div className="Field-control">
            <input
              value={refNumber}
              type="text"
              onChange={handleRefNumberChange}
              disabled={isCreating}
              className="App-input"
            />
          </div>
        </div>
        <div className="Field-group">
          <div className="Field-label">LOT:</div>
          <div className="Field-control">
            <input
              value={lot}
              type="text"
              onChange={handleLotChange}
              disabled={isCreating}
              className="App-input"
            />
          </div>
        </div>

        <div className="Form-error">{error}</div>
      </>
    );

    buttons = [
      {
        title: "Cancel",
        isMinor: true,
        onClick: () => props.onClose(false),
        disabled: isCreating,
      },
      {
        title: "Create",
        onClick: () => create(),
        disabled: !isValid || isCreating,
      },
    ];
  } else {
    contents = (
      <>
        <div className="Field-group">
          <div className="Field-label">REF:</div>
          <div className="Field-control">{refNumber}</div>
        </div>
        <div className="Field-group">
          <div className="Field-label">LOT:</div>
          <div className="Field-control">{lot}</div>
        </div>
        <div className="Field-group">
          <div className="Field-label">Created ID:</div>
          <div className="Field-control">{createdId}</div>
        </div>
        <div className="Form-success">Created successfully</div>
      </>
    );

    buttons = [
      {
        title: "Ok",
        onClick: () => props.onClose(true),
      },
    ];
  }

  return (
    <Modal title="Create ID for LOT" buttons={buttons}>
      {contents}
    </Modal>
  );
};
