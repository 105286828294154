import { useState } from "react";
import { getList, ListResponse } from "../request/getList";
import logo from "../assets/dx365-logo-service.png";
import { setToken } from "../request/setToken";

type Props = {
  onLogin: (response: ListResponse) => void;
};

export const LoginForm = (props: Props) => {
  const [token, setTokenValue] = useState<string>("");
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = (evt: React.FormEvent<HTMLFormElement>): void => {
    evt.preventDefault();
    if (!token.length) return;
    setIsFetching(true);
    setToken(token);
    getList()
      .then(props.onLogin)
      .catch((err) => {
        console.log(err);
        if (err === 403) {
          setError("Token is invalid");
        } else {
          setError("Unknown error");
        }
        setIsFetching(false);
      });
  };

  const handelTokenChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setError("");
    setTokenValue(evt.target.value);
  };

  return (
    <div className="App-form-valign-center">
      <form onSubmit={handleSubmit} className="App-form">
        <img src={logo} className="App-logo" alt="logo" />
        <fieldset className="App-fieldset" disabled={isFetching}>
          <input
            className="App-input"
            type="password"
            placeholder="Access key"
            value={token}
            onChange={handelTokenChange}
          />
          <button
            type="submit"
            className="App-button App-button_login"
            disabled={token.length === 0}
          >
            login
          </button>
        </fieldset>
        <div className="App-login-error">{error}</div>
      </form>
    </div>
  );
};
