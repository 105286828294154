export const Footer = () => {
  return (
    <div className="App-footer">
      <div className="App-footer_link-container">
        <a href="https://www.dx365.world/" className="App-footer_link">
          Dx365 UG
        </a>
      </div>
      <div className="App-footer_link-container">
        <a
          href="https://support.dx365.world/"
          className="App-footer_link"
        >
          support.dx365.world
        </a>
      </div>
    </div>
  );
};
