import logo from "../assets/dx365-logo-service.png";
import { MenuItem, Menu } from "../Menu";

type Props = {
  companyName: string;
  menuItems: MenuItem[];
};

export const Header = ({ companyName, menuItems }: Props) => {
  return (
    <div className="App-header">
      <div>
        <img src={logo} className="App-logo App-logo-header" alt="logo" />
        <span className="App-title"> + {companyName}</span>
      </div>

      <Menu items={menuItems} />
    </div>
  );
};
