type RequestUrl = "list" | "create-id" | "publish";

let _token: string | null = null;

const BASE_PATH = "/calibration/";

export const setToken = (token: string) => {
  _token = token;
};

type Method = "GET" | "POST";

type FetchOptions = {
  method: Method;
  headers: {
    "x-auth": string;
    "Content-Type"?: "application/json";
  };
  body?: string;
};

export const request = (url: RequestUrl, method: Method, body?: string) => {
  if (!_token) throw new Error("unauthorized: no token provided yet");
  const options: FetchOptions = {
    method,
    headers: {
      "x-auth": _token,
    },
  };
  if (body) {
    options.body = body;
    options.headers["Content-Type"] = "application/json";
  }
  return fetch(BASE_PATH + url, options);
};
