import { useState } from "react";
import "./App.css";
import { MenuItem } from "./Menu";
import { LoginForm } from "./LoginForm";
import { CalibrationList } from "./CalibrationList";
import { CreateIdModal } from "./CreateIdModal";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { ErrorState } from "./ErrorState";
import { ListResponse, Calibration, getList } from "./request/getList";
import { PublishModal } from "./PublishModal";

function App() {
  const [companyName, setCompanyName] = useState<string | null>(null);
  const [calibrations, setCalibrations] = useState<Calibration[] | null>(null);
  const [isCreatingId, setIsCreatingId] = useState<boolean>(false);
  const [isPublishing, setIsPublishing] = useState<boolean>(false);
  const [idToPublish, setIdToPublish] = useState<number | null>(null);

  const [error, setError] = useState<string | null>(null);

  const handleList = ({ calibrations, companyName }: ListResponse) => {
    setCompanyName(companyName);
    setCalibrations(calibrations.sort((a, b) => a.id - b.id));
  };

  const handleCreateIdModalClose = (isSuccess: boolean) => {
    setIsCreatingId(false);
    if (isSuccess) {
      getList()
        .then(handleList)
        .catch((err) => {
          setError("Something went wrong");
          setCalibrations(null);
        });
    }
  };

  const handlePublishModalClose = (isSuccess: boolean) => {
    setIsPublishing(false);
    if (isSuccess) {
      getList()
        .then(handleList)
        .catch((err) => {
          setError("Something went wrong");
          setCalibrations(null);
        });
    }
  };

  const menuItems: MenuItem[] = [
    {
      title: "Create ID for future LOT",
      callback: () => setIsCreatingId(true),
    },
  ];

  let contents = null;

  if (error) {
    contents = (
      <>
        <Header menuItems={[]} companyName={companyName!} />
        <ErrorState error={error} onDone={() => setError(null)} />
        <Footer />
      </>
    );
  } else if (calibrations === null) {
    contents = <LoginForm onLogin={handleList} />;
  } else {
    contents = (
      <>
        <Header menuItems={menuItems} companyName={companyName!} />
        <CalibrationList
          calibrations={calibrations}
          onPublishClicked={(id) => {
            setIsPublishing(true);
            setIdToPublish(id);
          }}
        />
        <Footer />
        {isCreatingId && <CreateIdModal onClose={handleCreateIdModalClose} />}
        {isPublishing && (
          <PublishModal
            onClose={handlePublishModalClose}
            calibration={calibrations.find((c) => c.id === idToPublish)!}
          />
        )}
      </>
    );
  }

  return <div className="App">{contents}</div>;
}

export default App;
