import ELEMENT_TABLE from "./ELEMENT_TABLE";

class Code128 {
	/*
	insert(target = document.body) {
		const canvas = document.createElement("canvas");
		const context = canvas.getContext("2d");
		const units = this.elements
			.map((item) => item[5])
			.join("")
			.split("");
		const uw = 1;
		const uh = uw * 50;
		canvas.height = uh;
		canvas.width = units.length * 3;
		const drawUnit = function (context, i, uw, uh, color) {
			context.save();
			try {
				context.beginPath();
				context.rect(uw * i, 0, uw, uh);
				if (color) {
					context.fillStyle = color;
				}
				context.closePath();
				context.fill();
			} finally {
				context.restore();
			}
		};
		for (let i = 0, len = units.length; i < len; i++) {
			if (+units[i]) {
				drawUnit(context, i, uw, uh, "#000");
			} else {
				drawUnit(context, i, uw, uh, "#fff");
			}
		}
		target.appendChild(canvas);
		return canvas;
	}*/
}

const SVG_WIDTH = 200;
const SVG_HEIGHT = 20;

const svgHead = `<?xml version="1.0" encoding="utf-8" standalone="yes"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<svg xmlns:xlink="http://www.w3.org/1999/xlink" class="barcode-canvas" version="1.1" style="shape-rendering:crispEdges;" xmlns="http://www.w3.org/2000/svg">
	<g text-rendering="geometricPrecision">
  	<rect x="0" y="0" width="${SVG_WIDTH}" height="${SVG_HEIGHT}" fill="#ffffff" />
`;

const svgEnd = `
	</g>
</svg>`;

const elementsToSvg = (elements) => {
	let svg = svgHead;

	const unit = SVG_WIDTH / (elements.length * 11 + 20);
	let position = 10 * unit;

	elements.forEach((element) => {
		element[4]
			.split("")
			.map(Number)
			.forEach((width, idx) => {
				width *= unit;
				if (idx % 2 === 0) {
					svg += `    <rect x="${position.toFixed(
						3
					)}" y="0" width="${width.toFixed(
						3
					)}" height="${SVG_HEIGHT}" fill="#000000" />\n`;
				}
				position += width;
			});
	});

	svg += svgEnd;

	return svg;
};

export const generateBarcode = (input) => {
	let start = 0;
	let end = 106;
	let elements = [];

	const chars = input.split("");
	if (/^[0-9]{1}$/.test(input)) {
		start = 103;
		elements = chars.map((item) => ELEMENT_TABLE.find((o) => o[1] === item));
	} else if (/^[0-9]+$/.test(input) && input.length % 2 === 0) {
		start = 105;
		for (let i = 0, len = chars.length; i < len; i += 2) {
			let key = `${chars[i]}${chars[i + 1]}`;
			elements.push(ELEMENT_TABLE.find((o) => o[3] === key));
		}
	} else if (/^[0-9]+$/.test(input) && input.length % 2 === 1) {
		start = 105;
		for (let i = 0, len = chars.length - 1; i < len; i += 2) {
			let key = `${chars[i]}${chars[i + 1]}`;
			elements.push(ELEMENT_TABLE.find((o) => o[3] === key));
		}
		elements.push(ELEMENT_TABLE[101]);
		elements.push(ELEMENT_TABLE.find((o) => o[1] === chars[chars.length - 1]));
	} else if (/^[A-Z0-9]+$/.test(input)) {
		start = 103;
		elements = chars.map((item) => ELEMENT_TABLE.find((o) => o[1] === item));
	} else {
		start = 104;
		elements = chars.map((item) => ELEMENT_TABLE.find((o) => o[2] === item));
	}

	let checkSum = 0;
	elements.forEach((item, i) => {
		checkSum += item[0] * (i + 1);
	});
	checkSum += start;

	elements.push(ELEMENT_TABLE[checkSum % 103]);
	elements.unshift(ELEMENT_TABLE[start]);
	elements.push(ELEMENT_TABLE[end]);

	return elementsToSvg(elements);
};
