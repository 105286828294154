import { Calibration, CalibrationStatus } from "../request/getList";
import moment from "moment";
import "./styles.css";
import downloadIcon from "../assets/download.svg";
import publish from "../assets/publish.svg";
import { Status } from "./Status";

type Props = {
  calibrations: Calibration[];
  onPublishClicked: (id: number) => void;
};

const DATE_FORMAT = "D MMM YYYY";
const TIME_FORMAT = "H:mm:ss (Z)";

export const CalibrationList = (props: Props) => {
  return (
    <div className="App-section">
      {props.calibrations.map(
        (calibration: Calibration): React.ReactNode => (
          <div className="Calibration-list_item" key={calibration.id}>
            <div className="Calibration-list_item-row">
              <div className="Calibration-list_item-feild Calibration-list_id">
                <div className="Calibration-list_field-name">Uniq ID</div>
                {calibration.id}
              </div>
              <div className="Calibration-list_item-feild Calibration-list_ref">
                <div className="Calibration-list_field-name">REF</div>
                {calibration.refNumber}
              </div>
              <div className="Calibration-list_item-feild Calibration-list_lot">
                <div className="Calibration-list_field-name">LOT</div>
                {calibration.lot}
              </div>

              <div className="Calibration-list_item-feild">
                <div className="Calibration-list_field-name">Created</div>
                <div>{moment(calibration.dateCreated).format(DATE_FORMAT)}</div>
                <div className="Calibration-list_time">
                  {moment(calibration.dateCreated).format(TIME_FORMAT)}
                </div>
              </div>
              <div className="Calibration-list_item-feild">
                {(calibration.status === CalibrationStatus.Uploaded ||
                  calibration.status === CalibrationStatus.Published) && (
                  <>
                    <div className="Calibration-list_field-name">Uploaded</div>
                    <div>
                      {moment(calibration.dateUploaded).format(DATE_FORMAT)}
                    </div>
                    <div className="Calibration-list_time">
                      {moment(calibration.dateUploaded).format(TIME_FORMAT)}
                    </div>
                  </>
                )}
              </div>

              <div className="Calibration-list_item-feild">
                {calibration.status === CalibrationStatus.Published && (
                  <div className="Calibration-list_item-feild">
                    <div className="Calibration-list_field-name">Published</div>
                    <div>
                      {moment(calibration.datePublished).format(DATE_FORMAT)}
                    </div>
                    <div className="Calibration-list_time">
                      {moment(calibration.datePublished).format(TIME_FORMAT)}
                    </div>
                  </div>
                )}
                {calibration.status === CalibrationStatus.Uploaded && (
                  <button
                    className="App-button"
                    onClick={() => props.onPublishClicked(calibration.id)}
                  >
                    <span className="App-link-text">Publish</span>
                    <img
                      src={publish}
                      alt="download"
                      className="App-link-icon"
                    />
                  </button>
                )}
              </div>
            </div>

            <div className="Calibration-list_item-row">
              <div className="Calibration-list_item-feild Calibration-list_id"></div>
              <div className="Calibration-list_item-feild Calibration-list_field-status">
                <div className="Calibration-list_field-name">Status</div>
                <Status status={calibration.status} />
              </div>
              <div className="Calibration-list_item-feild Calibration-list_testname">
                {/*<div className="Calibration-list_field-name">Test name</div>
                <div>Ferritin</div>*/}
              </div>
              <div className="Calibration-list_item-feild">
                <div className="Calibration-list_field-name">Download</div>
                <a
                  href={
                    "data:text/svg;charset=utf-8," +
                    encodeURIComponent(calibration.barcodeSvg)
                  }
                  download={
                    "bar_" +
                    calibration.id +
                    "_" +
                    calibration.refNumber +
                    "_" +
                    calibration.lot +
                    ".svg"
                  }
                  className="App-link"
                >
                  <span className="App-link-text">Barcode</span>
                  <img
                    src={downloadIcon}
                    alt="download"
                    className="App-link-icon"
                  />
                </a>
              </div>
              <div className="Calibration-list_item-feild">
                {calibration.qrCodeSvg && (
                  <>
                    <div className="Calibration-list_field-name">Download</div>
                    <a
                      href={
                        "data:text/svg;charset=utf-8," +
                        encodeURIComponent(calibration.qrCodeSvg)
                      }
                      download={
                        "qr_" +
                        calibration.id +
                        "_" +
                        calibration.refNumber +
                        "_" +
                        calibration.lot +
                        ".svg"
                      }
                      className="App-link"
                    >
                      <span className="App-link-text">QR-code</span>
                      <img
                        src={downloadIcon}
                        alt="download"
                        className="App-link-icon"
                      />
                    </a>
                  </>
                )}
              </div>

              <div className="Calibration-list_item-feild"></div>
            </div>
          </div>
        )
      )}
    </div>
  );
};
