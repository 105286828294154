import { CalibrationStatus } from "../request/getList";
const statusToStringMap = {
  1: "published",
  2: "uploaded",
  3: "created",
};

type Props = {
  status: CalibrationStatus;
};

export const Status = ({ status }: Props) => {
  const statusText = statusToStringMap[status] ?? "unknown";
  return (
    <div
      className={`Calibration-list_status Calibration-list_status-${statusText}`}
    >
      {statusText}
    </div>
  );
};
