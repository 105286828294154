import { request } from "./setToken";

type RawResponse = {
  id: number;
};
export const createId = (refNumber: string, lot: string): Promise<number> => {
  return request("create-id", "POST", JSON.stringify({ refNumber, lot }))
    .then((response) => {
      if (!response.ok) {
        if (response.status === 403 || response.status === 409) {
          return Promise.reject(response.status);
        }
        throw new Error("Request failled");
      }
      return response.json();
    })
    .then((responseJson: RawResponse) => responseJson.id);
};
