export type MenuItem = {
  title: string;
  callback: () => void;
};

export type Props = {
  items: MenuItem[];
};

export const Menu = ({ items }: Props) => {
  return (
    <div className="Menu">
      {items.map((item, idx) => (
        <div className="Menu-item" onClick={item.callback} key={idx}>
          {item.title}
        </div>
      ))}
    </div>
  );
};
