import { request } from "./setToken";

export const publish = (id: number): Promise<void> => {
  return request("publish", "POST", JSON.stringify({ id })).then((response) => {
    if (!response.ok) {
      if (response.status === 403) {
        return Promise.reject(response.status);
      } else if (response.status === 409) {
        return Promise.reject(409);
      }
      throw new Error("Request failled");
    }
  });
};
