import { ChangeEvent, useState } from "react";
import { Modal } from "../Modal";
import { publish } from "../request/publish";
import { Calibration } from "../request/getList";

type Props = {
  onClose: (isSuccess: boolean) => void;
  calibration: Calibration;
};

export const PublishModal = (props: Props) => {
  const [isPublishing, setIsPublishing] = useState<boolean>(false);
  const [isPublished, setIsPublished] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handlePublish = () => {
    setIsPublishing(true);
    setError("");
    publish(props.calibration.id)
      .then(() => setIsPublished(true))
      .catch((e) => {
        if (e === 403) {
          setError("Unauthorized");
        } else if (e === 409) {
          setError("Conflict");
        } else {
          setError("Unknown error");
        }
      })
      .then(() => {
        setIsPublishing(false);
      });
  };

  let contents = null;
  let buttons = [];

  if (isPublished) {
    contents = <div className="Form-success">Published successfully</div>;

    buttons = [
      {
        title: "Ok",
        onClick: () => props.onClose(true),
      },
    ];
  } else {
    contents = (
      <>
        <div className="Form-error">{error}</div>
      </>
    );

    buttons = [
      {
        title: "Cancel",
        isMinor: true,
        onClick: () => props.onClose(false),
        disabled: isPublishing,
      },
      {
        title: "Publish",
        onClick: () => handlePublish(),
        disabled: isPublishing,
      },
    ];
  }

  return (
    <Modal title="Publish" buttons={buttons}>
      <div className="Field-group">
        <div className="Field-label">REF:</div>
        <div className="Field-control">{props.calibration.refNumber}</div>
      </div>
      <div className="Field-group">
        <div className="Field-label">LOT:</div>
        <div className="Field-control">{props.calibration.lot}</div>
      </div>
      <div className="Field-group">
        <div className="Field-label">ID:</div>
        <div className="Field-control">{props.calibration.id}</div>
      </div>

      {contents}
    </Modal>
  );
};
