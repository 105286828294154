import { request } from "./setToken";
import vendors from "../vendors.json";
import { generateBarcode } from "../barcode";
import QRCode from "qrcode-svg";

export enum CalibrationStatus {
  Published = 1,
  Uploaded = 2,
  Created = 3,
}

export type Calibration = {
  id: number;
  metadata: string | null;
  dateCreated: Date;
  datePublished: Date | null;
  dateUploaded: Date | null;
  status: CalibrationStatus;
  lot: string;
  refNumber: string;
  barcodeSvg: string;
  qrCodeSvg: string | null;
};

export type ListResponse = {
  companyName: string;
  calibrations: Calibration[];
};

type RawCalibration = {
  company: number;
  date_created: string;
  date_published: string | null;
  date_uploaded: string | null;
  filepath: string | null;
  id: number;
  last_modified_by: number;
  lot_id: string;
  metadata: string | null;
  ref_id: string;
  status: number;
};

type RawResponse = {
  calibrations: RawCalibration[];
  company: number;
};

const getVendorNameByShortCode = (id: number): string | null => {
  const item = vendors.items.find((item) => item.shortCode === id);
  return item ? item.name.en : null;
};

export const getList = (): Promise<ListResponse> => {
  return request("list", "GET")
    .then((response) => {
      if (!response.ok) {
        if (response.status === 403) {
          return Promise.reject(403);
        }
        throw new Error("Request failled");
      }
      return response.json();
    })
    .then((responseJson: RawResponse) => {
      return {
        companyName: getVendorNameByShortCode(responseJson.company) || "",
        calibrations: responseJson.calibrations.map(
          (calibration: RawCalibration) => {
            let qrCodeSvg = null;
            if (calibration.metadata) {
              const qrcode = new QRCode({
                content: calibration.metadata,
                join: true, //Crisp rendering and 4-5x reduced file size
              });
              qrCodeSvg = qrcode.svg();
            }

            return {
              dateCreated: new Date(calibration.date_created),
              datePublished: calibration.date_published
                ? new Date(calibration.date_published)
                : null,
              dateUploaded: calibration.date_uploaded
                ? new Date(calibration.date_uploaded)
                : null,
              status: calibration.status,
              id: calibration.id,
              lot: calibration.lot_id,
              refNumber: calibration.ref_id,
              metadata: calibration.metadata,
              barcodeSvg: generateBarcode(calibration.id.toString()),
              qrCodeSvg,
            };
          }
        ),
      };
    });
};
