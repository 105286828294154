type Props = {
  error: string;
  onDone: () => void;
};

export const ErrorState = ({ error, onDone }: Props) => {
  return (
    <section className="App-section">
      {error}
      <button className="App-button" title="Ok" onClick={onDone} />
    </section>
  );
};
