import "./styles.css";

type ModalButton = {
  title: string;
  onClick: () => void;
  disabled?: boolean;
  isMinor?: boolean;
};

type Props = {
  title: string;
  buttons: ModalButton[];
  children: React.ReactNode;
};

export const Modal = (props: Props) => {
  return (
    <div className="Modal-shadow">
      <div className="Modal-container">
        <div className="Modal-title">{props.title}</div>
        <div className="Modal-content">{props.children}</div>
        <div className="Modal-footer">
          {props.buttons.map((button, idx) => (
            <div className="Modal-footer_button-container">
              <button
                key={idx}
                className={
                  "App-button" + (button.isMinor ? " App-button-minor" : "")
                }
                disabled={button.disabled}
                onClick={button.onClick}
              >
                {button.title}
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
